import React, { useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProductAccordion from "../ProductAccordion/ProductAccordion";
import ProductAccordionPlus from "../ProductAccordionPlus/ProductAccordionPlus";
import ProductAccordionBottom from "../../components/ProductAccordionBottom/ProductAccordionBottom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import ProductView from "../../components/ProductView/ProductView";
import ProductViewPlus from "../ProductViewPlus/ProductViewPlus";
import wantedProduct_en from "../../assets/wantedProduct_en.glb";
import wantedProduct_plus from "../../assets/wantedProduct_plus.glb";
import video1 from "../../../src/assets/video/home_video.mp4";
import video2 from "../../../src/assets/video/visox_web_01.mp4";
import video3 from "../../../src/assets/video/visox_web_02_02.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ProductAccordionBottomPlus from "../ProductAccordionBottomPlus/ProductAccordionBottomPlus";

export default function ProductTab() {
  return (
    <main className="product_tab">
      <Tabs>
        <TabList className="tab_list">
          <Tab className="tab_one">visox</Tab>
          <Tab className="tab_two">visox plus</Tab>
        </TabList>

        <TabPanel>
          <div data-aos="fade-right">
            <div className="row ">
              <div className="col-lg-12 d-flex justify-content-center">
                <model-viewer
                  className="img-fluid xs"
                  style={{ height: "50vh" }}
                  src={wantedProduct_en}
                  ar
                  shadow-intensity="1"
                  camera-controls
                  touch-action="pan-y"
                ></model-viewer>
              </div>
            </div>
            <ProductView />
            <div className="container d-flex justify-content-center ">
              <div className="col-lg-10">
              <Carousel>
                  <video
                    autoPlay={true}
                    loop
                    muted
                    className="visox_product_video"
                  >
                    <source src={video1} type="video/mp4" />
                  </video>
                 <img src="/assets/images/main/visox_el.png"/>
                </Carousel>
             
              

                <ProductAccordion />
                <ProductAccordionBottom />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div data-aos="fade-left">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center ">
                <model-viewer
                  className="img-fluid xs"
                  style={{ height: "50vh" }}
                  src={wantedProduct_plus}
                  ar
                  shadow-intensity="1"
                  camera-controls
                  touch-action="pan-y"
                ></model-viewer>
              </div>
            </div>
            <ProductViewPlus />

            <div className="container d-flex justify-content-center">
              <div className="col-lg-10">
                <img className="/assets/images/main/visox_plus.png" />
                <Carousel>
                  <video
                    autoPlay={true}
                    loop
                    muted
                    className="visox_product_video"
                  >
                    <source src={video2} type="video/mp4" />
                  </video>
                  <video
                    autoPlay={true}
                    loop
                    muted
                    className="visox_product_video"
                  >
                    <source src={video3} type="video/mp4" />
                  </video>
                </Carousel>{" "}
                <ProductAccordionPlus />
                <ProductAccordionBottomPlus />
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </main>
  );
}
