import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import "./assets/css/styles.css";
import Products from "./routes/Products";
import Sss from "./routes/Sss";
import Need from "./routes/Need";
import Contact from "./routes/Contact";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ScrollToTop from "../src/components/ScrollToTop/scrolltotop";
import ScrollToTopButton from "./routes/ScrollToTopButton";
import NotFound from "./components/NotFound";


function App(props) {
  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./data/Translation/english.json"),
        tr: require("./data/Translation/turkish.json"),
      },
    })
  );

  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/products" element={<Products />}></Route>
          <Route path="/sss" element={<Sss />}></Route>
          <Route path="/need" element={<Need />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>{" "}
      <ScrollToTopButton />
    </BrowserRouter>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(App));
