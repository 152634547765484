import React, { useState, useRef } from "react";
import "../../assets/css/ProductAccordion.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

AOS.init();
const ProductAccordionPlus = (props) => {
  const form = useRef();
  const [quantity, setQuantity] = useState(0);

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      quantity: quantity,
    };

    emailjs
      .sendForm(
        "service_9dmkgxb",
        "template_267qhff",
        form.current,
        "t20whUQfMYkQl9bLe",
        templateParams
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <>
      <ToastContainer />
      <div class="row mt-5">
        <div class="col">
          <div class="tabs">
            <div class="tab">
              <input className="accordion_input" type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1">
                visox plus
              </label>
              <div class="tab-content">
                <div
                  data-aos="fade-top"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-3 col-md-2 col-sm-3 col-xs-4 col-6 res_button_one">
                      <button className=" btn-outline-dark-contact active btn_one">
                        on prem
                      </button>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-3 col-xs-4 col-6  res_button_two ">
                      <button className="btn btn-outline-dark-contact active btn_two">
                        <p className="high_opacity_contact"> on cloud</p>
                        <p className="low_opacity_product">
                          {props.strings["product_text_1"]}
                        </p>
                      </button>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-3 col-xs-4 col-6  res_button_three ">
                      {" "}
                      <button className="btn btn-outline-dark-contact active btn_three">
                        <p className="high_opacity_contact"> on cloud</p>
                        <p className="low_opacity_product">
                          {props.strings["product_text_2"]}
                        </p>
                      </button>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-3 col-xs-4 col-6  res_button_four ">
                      {" "}
                      <button className="btn btn-outline-dark-contact active btn_four">
                        <p className="high_opacity_contact"> on cloud</p>
                        <p className="low_opacity_product">
                          {props.strings["product_text_3"]}
                        </p>
                      </button>
                    </div>
                  </div>
                  <form onSubmit={sendEmail} ref={form}>
                    {" "}
                    <div className="row pt-5 product_form_left">
                      <div className="col-lg-7">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name_surname"
                            className="form-control-product"
                            id="exampleFormControlInput1"
                            placeholder={props.strings["product_text_4"]}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="company"
                            className="form-control-product"
                            id="exampleFormControlInput1"
                            placeholder={props.strings["product_text_5"]}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="phone"
                            className="form-control-product"
                            id="exampleFormControlInput1"
                            placeholder={props.strings["product_text_6"]}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="subject"
                            className="form-control-product"
                            id="exampleFormControlInput1"
                            placeholder={props.strings["product_text_7"]}
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control-product"
                            id="exampleFormControlTextarea1"
                            name="message"
                            rows="1"
                            placeholder={props.strings["product_text_8"]}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-5 d-flex flex-column justify-content-start align-items-center">
                        <img
                          className="product_visox"
                          src="assets/images/main/visox_plus.png"
                        />
                        <div className="d-flex math_section">
                          <span
                            className="btn btn-outline-dark-math- btn-sm"
                            onClick={decrementQuantity}
                          >
                            -
                          </span>
                          <h3
                            className="padding_3"
                            name="quantity"
                            value={quantity}
                          >
                            {quantity}
                          </h3>
                          <input
                            type="hidden"
                            name="quantity"
                            value={quantity}
                          />
                          <span
                            className="btn btn-outline-dark-math-- btn-sm"
                            onClick={incrementQuantity}
                          >
                            +
                          </span>
                        </div>
                        <div className="pt-3">
                          <button
                            type="submit"
                            className="btn btn-outline-dark-contact padding_button_product"
                          >
                            <div className="col-lg-12">
                              {props.strings["product_text_9"]}
                            </div>{" "}
                          </button>
                        </div>{" "}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductAccordionPlus.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(ProductAccordionPlus));
