import React from "react";
import "../../assets/css/Footer.css";
import {AiFillLinkedin, AiOutlineInstagram} from "react-icons/ai";
import {Link} from "react-router-dom";
import {multilanguage,} from "redux-multilanguage";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="row m-0">
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                    <Link to="/">
                        {" "}
                        <img
                            className="footer-logo footer_logo_res_padding"
                            src="/assets/images/logo/visox_logo_white.svg"
                        />
                    </Link>
                </div>
                <div className="col-lg-10"></div>
            </div>
            <div className="row m-0 align-items-end">
                <div className="col-lg-2">
                    <ul className="footer_res_texts">
                        <li>
                            <Link className="footer_link" to="/products">
                                {props.strings["header_text_1"]}
                            </Link>
                        </li>
                        <li>
                            <Link className="footer_link" to="/sss">
                                {props.strings["header_text_2"]}
                            </Link>
                        </li>
                        <li>
                            <Link className="footer_link" to="/need">
                                {props.strings["header_text_3"]}
                            </Link>
                        </li>
                        <li>
                            <Link className="footer_link" to="/contact">
                                {props.strings["header_text_4"]}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-9 line"></div>
                <div className="col-lg-1">
                    <div className="d-flex res_footer_icons">
                        <a href="https://www.instagram.com/visox.io/" target="_blank">
                            {" "}
                            <AiOutlineInstagram className="social_media_icon"/>
                        </a>

                        <a
                            href="https://www.linkedin.com/company/visox-io/"
                            target="_blank"
                        >
                            <AiFillLinkedin className="social_media_icon"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="madeby mt-5 mb-0 d-flex flex-column col-6"></div>
        </div>
    );
};

Footer.propTypes = {
    dispatch: PropTypes.func,
    strings: PropTypes.object,
    currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Footer));
