import React from "react";
import "../../assets/css/Accordion.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaSquare } from "react-icons/fa";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";



function AccordionSection(props) {
  return (
    <section className="accordion_section">


      <div className="container">
        <div className="row m-0 top_accordion">
          <div className="col-lg-6">
            <h1 className="text-start accordion_margin accordion_stroke_title">
            {ReactHtmlParser(props.strings["home_text_9"])}

             
            </h1>
            <div className="pt-3">
              {" "}
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="d-flex">
                    {" "}
                    <img
                      className="accordion_down_icon_top"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />{" "}
                    <p className="home_accordion_title margin_0"> {props.strings["home_text_10"]}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                      {props.strings["home_text_11"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="d-flex">
                    {" "}
                    <img
                      className="accordion_down_icon_top"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />{" "}
                    <p className="home_accordion_title margin_0"> {props.strings["home_text_12"]}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="d-flex">
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                      {props.strings["home_text_13"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="d-flex">
                    <img
                      className="accordion_down_icon_top"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />{" "}
                    <p className="home_accordion_title margin_0">{props.strings["home_text_14"]}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                      {props.strings["home_text_15"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="d-flex">
                    <img
                      className="accordion_down_icon_top"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />{" "}
                    <p className="home_accordion_title margin_0"> {props.strings["home_text_16"]}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                      {props.strings["home_text_17"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 res_cloud_img">
            <img
              className="server_img"
              src="/assets/images/main/cloud_03.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
}


AccordionSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(AccordionSection));