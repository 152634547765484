import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "../assets/css/Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FiMapPin } from "react-icons/fi";
import { Slide } from "react-reveal";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
AOS.init();

const Contact = (props) => {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q44v1oq",
        "template_52z4m88",
        form.current,
        "ECLYa8BAKpnVhRZK6"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }

  const { currentLanguageCode } = props;

  return (
    <>
      <Header />
      <ToastContainer />
      <main className="contact">
        <div className="container">
          {currentLanguageCode == "tr" ? (
            <div className="row left_form pb-5">
              <div className="col-lg-6 col-md-10 contact_res_center ">
                <div
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h1 className="contact_left_text"> bize </h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-11 contact_res_center_two">
                <div
                  data-aos="fade-left"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h1 className="stroke_title_contact"> ulaşın </h1>
                </div>
              </div>
            </div>
          ) : (
            <div className="row left_form pb-5">
              <div className="col-lg-8 col-md-10 contact_res_center ">
                <div
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h1 className="contact_left_text"> contact </h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-11 contact_res_center_two">
                <div
                  data-aos="fade-left"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h1 className="stroke_title_contact"> us </h1>
                </div>
              </div>
            </div>
          )}

          <Slide bottom>
            {" "}
            <div className="row m-0 d-flex justify-content-end align-items-center">
              <div className="col-lg-6 ">
                <form onSubmit={sendEmail} ref={form}>
                  <div class="form-floating mb-3">
                    <input
                      name="name_surname"
                      type="text"
                      class="form-control"
                      id="floatingInput name_surname"
                      placeholder="ad soyad"
                    />
                    <label for="floatingInput">
                      {props.strings["contact_text_3"]}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      name="email"
                      type="email"
                      class="form-control"
                      id="floatingInput email"
                      placeholder="e-posta"
                    />
                    <label for="floatingInput">
                      {props.strings["contact_text_4"]}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      name="phone"
                      type="number"
                      class="form-control"
                      id="floatingInput phone"
                      placeholder="telefon"
                    />
                    <label for="floatingInput">
                      {props.strings["contact_text_5"]}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      name="subject"
                      type="text"
                      class="form-control"
                      id="floatingInput subject"
                      placeholder="konu"
                    />
                    <label for="floatingInput">
                      {props.strings["contact_text_6"]}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <textarea
                      name="message"
                      class="form-control"
                      id="exampleFormControlTextarea1 message"
                      rows="3"
                      placeholder="mesaj"
                    ></textarea>
                    <label for="floatingInput">
                      {props.strings["contact_text_7"]}
                    </label>
                  </div>
                  <div className="row padding_button">
                    <div className="col-lg-12  text-center up">
                      <button className="btn need_contact_button padding_button_contact">
                        {props.strings["contact_text_8"]}
                      </button>
                    </div>
                  </div>{" "}
                </form>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mobile_adress">
                <div className="pb-3">
                  {" "}
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8  ">
                      <FiMapPin className="contact_pin_icon" />
                      <span className="adress_contact">USA</span>
                    </div>
                  </div>
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8 d-flex justify-content-start">
                      <span>
                        {" "}
                        <p className="low_opacity_contact">New York, NY</p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  {" "}
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8  ">
                      <FiMapPin className="contact_pin_icon" />
                      <span className="adress_contact">Qatar</span>
                    </div>
                  </div>
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8 d-flex justify-content-start">
                      <span>
                        {" "}
                        <p className="low_opacity_contact">Doha</p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  {" "}
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8 ">
                      <FiMapPin className="contact_pin_icon" />
                      <span className="adress_contact">Türkiye</span>
                    </div>
                  </div>
                  <div className="row adress_end d-flex justify-content-center">
                    <div className="col-lg-8 d-flex justify-content-start">
                      <span>
                        {" "}
                        <p className="low_opacity_contact">Ankara</p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  {" "}
                  <div className="col-lg-8 contact_footer">
                    <div>
                      {" "}
                      <img
                        className="contact-arrow-down"
                        src="/assets/images/logo/down_arrow.svg"
                      />
                    </div>
                    <div>
                      {" "}
                      <span>
                        {" "}
                        <p className="low_opacity_contact_mail">
                          <a href="mailto:info@visox.io">info@visox.io</a>
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </main>
      <Footer />
    </>
  );
};

Contact.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Contact));
