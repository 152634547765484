import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaSquare } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const ProductAccordionBottom = (props) => {
  return (
    <div className="product_accordion_bottom">
      <div className="pt-3 pb-5 d-lg-block">
        {" "}
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="d-flex ">
              {" "}
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_10"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="d-lg-block-accordion">
              <div className="row ">
                <div className="col-2 col-xs-4">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_1"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 col-xs-8 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_12"]}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_13"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>

                <div className="col-4 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_14"]}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_15"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_16"]}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_17"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_18"]}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_19"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 ">
                  <p className="product_desc">94x94x17 mm</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_21"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 ">
                  <p className="product_desc">IP41</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_23"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_24"]}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="product_desc ">
                    {" "}
                    {props.strings["product_text_25"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-4 ">
                  <p className="product_desc">CE</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              {" "}
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_27"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_28"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_29"]}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_55"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">DV300</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">RAM</p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">512 mb</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">ROM</p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1">
                  <p className="product_desc">4 gb</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_30"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">50.000</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_31"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">2.8 inch</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_32"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1">
                  <p className="product_desc">2 mp</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_33"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">IR</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_34"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_35"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">linux</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">wi-fi</p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">DV300</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">bluetooth</p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">512mb</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_36"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_37"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_38"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_39"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_40"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_41"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">wiagand</p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-5 ">
                  <p className="product_desc">
                    {props.strings["product_text_42"]}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_43"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_44"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-1 ">
                  <p className="product_desc">01</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_45"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_46"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_47"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_48"]}{" "}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_49"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-2 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_50"]}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">PoE</p>
                </div>
                <div className="col-3 line_product"></div>
                <div className="col-7 ">
                  <p className="product_desc">
                    {props.strings["product_text_51"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_52"]}{" "}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row ">
                <div className="col-2">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_53"]}
                  </p>
                </div>
                <div className="col-5 line_product"></div>
                <div className="col-3 ">
                  <p className="product_desc">
                    {" "}
                    {props.strings["product_text_54"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="pt-3 pb-3 mobile-block">
        {" "}
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="d-flex ">
              {" "}
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {props.strings["product_text_10"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="d-lg-block-accordion">
              <div className="row d-flex justify-content-center">
                <div className="col-6 ">
                  <p className="product_desc">
                    {props.strings["product_text_11"]}{" "}
                  </p>
                </div>
                <div className="col-6  d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_12"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_13"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">
                    {props.strings["product_text_14"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_15"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">
                    {props.strings["product_text_16"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_17"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">
                    {props.strings["product_text_18"]}{" "}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_19"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_20"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_21"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">IP41</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_23"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_24"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center ">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_25"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">CE</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              {" "}
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {props.strings["product_text_27"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_28"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">linux</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_55"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">DV300</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">RAM</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">512 mb</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">ROM</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">4 gb</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_30"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">50.000</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_31"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">2.8 inch</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_32"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">2 mp</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center ">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_33"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">IR</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {props.strings["product_text_34"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_35"]}{" "}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">linux</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">wi-fi</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">DV300</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center ">
                <div className="col-6">
                  <p className="product_desc">bluetooth</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">512mb</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_36"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_37"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_38"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_39"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_40"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_41"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">wiagand</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_42"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_43"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center ">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_44"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">01</p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {props.strings["product_text_45"]}{" "}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_46"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc">
                    {props.strings["product_text_47"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {" "}
                {props.strings["product_text_48"]}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_49"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_50"]}
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">PoE</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_51"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="product_accordion border_bottom_product"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="d-flex">
              <img
                className="accordion_down_icon_top"
                src="/assets/images/logo/down_arrow_02.svg"
              />{" "}
              <p className="product_accordion_title margin_0">
                {props.strings["product_text_52"]}{" "}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <p className="product_desc">
                    {props.strings["product_text_53"]}
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <p className="product_desc text-end">
                    {props.strings["product_text_54"]}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

ProductAccordionBottom.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(ProductAccordionBottom));
