import React from "react";
import "../../assets/css/Navbar.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { AiFillFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { useEffect } from "react";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    function handleMenuClose(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleMenuClose);

    return () => {
      document.removeEventListener("mousedown", handleMenuClose);
    };
  }, []);

  const isActive = (path, match, location) => {
    if (!match) {
      return false;
    }
    return true;
  };

  return (
    <>
      <nav className="nav">
        <NavLink to="/" className="d-flex align-items-center">
          {" "}
          <img
            className="header_logo_white"
            src="/assets/images/logo/visox_logo_white.svg"
          />
        </NavLink>
        <ul
          className="language_ul"
          style={{ paddingTop: "4vh", paddingRight: "2vh" }}
        >
          <li>
            <NavLink
              isActive={isActive.bind(null, "/products")}
              exact
              to="/products"
              className="header_Navlink"
            >
              {props.strings["header_text_1"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/sss")}
              exact
              to="/sss"
              className="header_Navlink"
            >
              {props.strings["header_text_2"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/need")}
              exact
              className="header_Navlink"
              to="/need"
            >
              {props.strings["header_text_3"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/contact")}
              exact
              className="header_Navlink"
              to="/contact"
            >
              {props.strings["header_text_4"]}
            </NavLink>
          </li>
        </ul>
        <div
          className="language"
          role="radiogroup"
          aria-labelledby="language-switcher1"
        >
          {" "}
          <div className="language__container--left language__container--fr">
            <input
              onChange={(e) => {
                props.dispatch(changeLanguage(e.target.value));
              }}
              className="language__control"
              type="radio"
              id="language1-1"
              aria-label="en"
              name="en"
              value={"en"}
              checked={props.currentLanguageCode == "en" ? true : false}
            />
            <label className="language__label__black" htmlFor="language1-1">
              en<span className="hidden"></span>
            </label>
          </div>{" "}
          <div className="language__container--right language__container--en">
            <input
              onChange={(e) => {
                props.dispatch(changeLanguage(e.target.value));
              }}
              className="language__control"
              type="radio"
              id="language1-2"
              aria-label="tr"
              name="tr"
              value={"tr"}
              checked={props.currentLanguageCode == "tr" ? true : false}
            />
            <label className="language__label__black" htmlFor="language1-2">
              tr<span className="hidden"> </span>
            </label>
          </div>
        </div>
      </nav>

      <input
        type="checkbox"
        id="ham-menu"
        checked={menuOpen}
        onChange={() => setMenuOpen(!menuOpen)}
      />
      <div className="header_res" ref={menuRef}>
        {" "}
        <label for="ham-menu">
          <div class="hide-des">
            <span class="menu-line"></span>
            <span class="menu-line"></span>
            <span class="menu-line"></span>
          </div>
        </label>
        <div className="d-flex justify-content-center align-items-center">
          <Link to="/">
            {" "}
            <img
              className="res_logo_white"
              src="/assets/images/logo/visox_logo_white.svg"
            />
          </Link>
        </div>{" "}
      </div>
      <div class="full-page-green"></div>
      <div class="ham-menu">
        <ul class="centre-text bold-text">
          <li>
            <NavLink
              isActive={isActive.bind(null, "/")}
              exact
              className="res_menu_link"
              to="/"
            >
              {props.strings["header_text_0"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/products")}
              exact
              className="res_menu_link"
              to="/products"
            >
              {props.strings["header_text_1"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/sss")}
              exact
              className="res_menu_link"
              to="/sss"
            >
              {props.strings["header_text_2"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/need")}
              exact
              className="res_menu_link"
              to="/need"
            >
              {props.strings["header_text_3"]}
            </NavLink>
          </li>
          <li>
            <NavLink
              isActive={isActive.bind(null, "/contact")}
              exact
              className="res_menu_link"
              to="/contact"
            >
              {props.strings["header_text_4"]}
            </NavLink>
          </li>{" "}
          <div
            className="language"
            role="radiogroup"
            aria-labelledby="language-switcher1"
          >
            {" "}
            <div className="language__container--left language__container--fr">
              <input
                onChange={(e) => {
                  props.dispatch(changeLanguage(e.target.value));
                }}
                className="language__control"
                type="radio"
                id="language1-1"
                aria-label="en"
                name="en"
                value={"en"}
                checked={props.currentLanguageCode == "en" ? true : false}
              />
              <label className="language__label__black" htmlFor="language1-1">
                en<span className="hidden"></span>
              </label>
            </div>{" "}
            <div className="language__container--right language__container--en">
              <input
                onChange={(e) => {
                  props.dispatch(changeLanguage(e.target.value));
                }}
                className="language__control"
                type="radio"
                id="language1-2"
                aria-label="tr"
                name="tr"
                value={"tr"}
                checked={props.currentLanguageCode == "tr" ? true : false}
              />
              <label className="language__label__black" htmlFor="language1-2">
                tr<span className="hidden"> </span>
              </label>
            </div>
          </div>
          <li className="d-flex pb-3 bottom_icon">
            <a href="https://www.instagram.com/visox.io/" target="_blank">
              {" "}
              <AiOutlineInstagram className="social_media_icon_ham" />
            </a>

            <a href="https://www.linkedin.com/company/visox-io/" target="_blank">
              <AiFillLinkedin className="social_media_icon_ham" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Header));
