import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { VscTriangleRight } from "react-icons/vsc";
import { FaSquare } from "react-icons/fa"; // Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import required modules
import { Navigation } from "swiper";
import ReactHtmlParser from "react-html-parser";

function HomeSwiper(props) {
  return (
    <div className="home_swiper">
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <div className="swiper_inner">
            <div className="swiper_height_class">
              <h1 className="text-start  font-48 res_title white_home_text">
                {ReactHtmlParser(props.strings["home_text_1"])}
              </h1>
              <div className="d-flex">
                {" "}
                <img
                  src="/assets/images/logo/down_arrow.svg"
                  className="arrow_icon"
                />
                <h5 className=" pt-02 pl-05 white_home_text">VİSOX</h5>
              </div>
              <div className="d-flex pt-3">
                <FaSquare className="square_icon" />
                <p className="res_font pl-1 white_home_text_p">
                  {props.strings["home_text_2"]}
                </p>
              </div>

              <div>
                <p className=" res_font pl-3 white_home_text_p">
                  {props.strings["home_text_3"]}
                </p>

                <p
                  className="
                mb-3 res_font pl-3 white_home_text_p"
                >
                  {props.strings["home_text_4"]}
                </p>
                <br />
              </div>
            </div>
            <div className="d-flex justify-content-center height_number">
              <h1 className="slider_number_one white_home_text">01 / 02</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {" "}
          <div className="swiper_inner">
            <div className="swiper_height_class">
              <h1 className="text-start  font-48 res_title white_home_text">
                {ReactHtmlParser(props.strings["home_text_5"])}
              </h1>
              <div className="d-flex">
                {" "}
                <img
                  src="/assets/images/logo/down_arrow.svg"
                  className="arrow_icon"
                />
                <h5 className=" pt-02 pl-05 white_home_text">VİSOX</h5>
              </div>
              <div className="d-flex pt-3">
                <FaSquare className="square_icon" />
                <p className=" res_font pl-1 white_home_text_p">
                {props.strings["home_text_6"]}
                </p>
              </div>

              <div>
                <p className="res_font pl-3 white_home_text_p">
                {props.strings["home_text_7"]}
                  
                </p>
                <p className=" res_font pl-3 white_home_text_p">
                {props.strings["home_text_8"]}

                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center height_number">
              <h1 className="slider_number_two white_home_text">02 / 02</h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

HomeSwiper.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(HomeSwiper));
