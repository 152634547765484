import React from "react";
import video1 from "../../src/assets/video/visox_yazisiz.mp4";
import video2 from "../../src/assets/video/visox_yazisiz_mobil.mp4";
import video3 from "../../src/assets/video/visox_eng_web.mp4";
import HomeSwiper from "../components/HomeSwiper/HomeSwiper";
import "../assets/css/Home.css";
import AccordionSection from "../components/AccordionSection/AccordionSection";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import BlackBgSection from "../components/BlackBgSection/BlackBgSection";
import HeaderWhite from "../components/HeaderWhite/HeaderWhite";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaSquare } from "react-icons/fa"; // Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import ReactHtmlParser from "react-html-parser";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import required modules
import { Navigation } from "swiper";

const Home = (props) => {
  const { currentLanguageCode } = props;

  return (
    <>
      <HeaderWhite />
      <>
        {currentLanguageCode == "tr" ? (
          <video
            className=" rounded shadow-sm home_video  web_home_video"
            width="100%"
            height="100%"
            autoPlay={true}
            loop
            muted
            // webkit-playsInLine
            playsInline
          >
            <source src={video1} type="video/mp4" />
          </video>
        ) : (
          <video
            className=" rounded shadow-sm home_video  web_home_video"
            width="100%"
            height="100%"
            autoPlay={true}
            loop
            muted
            // webkit-playsInLine
            playsInline
          >
            <source src={video3} type="video/mp4" />
          </video>
        )}
        <video
          className="rounded shadow-sm home_video  mobil_home_video"
          width="100%"
          height="100%"
          autoPlay={true}
          loop
          muted
          // webkit-playsInLine
          playsInline
        >
          <source src={video2} type="video/mp4" />
        </video>
        <div class="overlay web_end">
          <div className="col-lg-5 col-md-8 col-sm-12 col-xs-4 col-12 web_swiper">
            <HomeSwiper />
          </div>
        </div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper_res"
        >
          <SwiperSlide>
            <div className="swiper_inner">
              <div className="swiper_height_class">
                <h1 className="text-start  font-48 res_title ">
                  {ReactHtmlParser(props.strings["home_text_1"])}
                </h1>
                <div className="d-flex">
                  {" "}
                  <img
                    src="/assets/images/logo/down_arrow.svg"
                    className="arrow_icon"
                  />
                  <h5 className=" pt-02 pl-05 ">VİSOX</h5>
                </div>
                <div className="d-flex pt-3">
                  <FaSquare className="square_icon" />
                  <p className="res_font pl-1 ">
                    {props.strings["home_text_2"]}
                  </p>
                </div>

                <div>
                  <p className=" res_font pl-3 ">
                    {props.strings["home_text_3"]}
                  </p>
                  <p className=" res_font pl-3 ">
                    {props.strings["home_text_4"]}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center height_number">
                <h1 className="slider_number_one ">01 / 02</h1>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {" "}
            <div className="swiper_inner">
              <div className="swiper_height_class">
                <h1 className="text-start  font-48 res_title ">
                  {ReactHtmlParser(props.strings["home_text_5"])}
                </h1>
                <div className="d-flex">
                  {" "}
                  <img
                    src="/assets/images/logo/down_arrow.svg"
                    className="arrow_icon"
                  />
                  <h5 className=" pt-02 pl-05 ">VİSOX</h5>
                </div>
                <div className="d-flex pt-3">
                  <FaSquare className="square_icon" />
                  <p className=" res_font pl-1 ">
                    {props.strings["home_text_6"]}
                  </p>
                </div>

                <div>
                  <p className="res_font pl-3 ">
                    {props.strings["home_text_7"]}
                  </p>
                  <p className=" res_font pl-3 ">
                    {props.strings["home_text_8"]}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center height_number">
                <h1 className="slider_number_two ">02 / 02</h1>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>{" "}
        <BlackBgSection />
        <AccordionSection />
      </>
      <Footer />
    </>
  );
};

Home.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Home));
