import React from "react";
import Header from "../components/Header/Header";
import ProductTab from "../components/ProductTab/ProductTab";
import "../assets/css/Product.css";
import Footer from "../components/Footer/Footer.js";

const Products = () => {
  return (
    <>
      <Header />
      <main className="product">
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-12">
            <ProductTab />
          </div>
        </div>{" "}
      </main>
      <Footer />
    </>
  );
};

export default Products;
