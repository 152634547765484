import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/BlackBgSection.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaSquare } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import wantedProduct_en from "../../assets/wantedProduct_en.glb";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
const BlackBgSection = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <main className="black_bg_section">
      <div className="container">
        <div className="row ">
          <h2 className=" text-center accordion_title_stroke">{props.strings["47"]}</h2>
          <div className=" text-center accordion_stroke_inner">
            <p className="pb-3 pt-3 ">
            {props.strings["48"]}
            </p>
          </div>
        </div>
        <div className="row res_bg_section">
          <div
            className="col-lg-6 visox_product_home_res"
            id="home_reveal_animation"
          >
            <Swiper loop={true} navigation={true} modules={[Navigation]}>
              <SwiperSlide>
                <img
                  className="swiper_product_one"
                  src="/assets/images/main/visox_render_view_02.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper_product_two"
                  src="/assets/images/main/visox_plus_render_view_01.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper_product_three"
                  src="/assets/images/main/visox_hand_held-min.png"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="col-lg-6 mobil_black_background">
            {" "}
            <div className="res_accordion_black">
              <Accordion className="black_bg_accordion " defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="black_bg_text_white d-flex">
                    {" "}
                    <img
                      className="accordion_down_icon"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />{" "}
                    <p className="home_accordion_title margin_0">
                      {" "}
                      {props.strings["home_text_18"]}{" "}
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="black_bg_text_white ">
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                        {props.strings["home_text_19"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="black_bg_accordion" defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="black_bg_text_white d-flex">
                    {" "}
                    <img
                      className="accordion_down_icon"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />
                    <p className="home_accordion_title margin_0">
                      {" "}
                      {props.strings["home_text_20"]}
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="black_bg_text_white d-flex">
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                        {props.strings["home_text_21"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="black_bg_accordion" defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="black_bg_text_white d-flex">
                    <img
                      className="accordion_down_icon"
                      src="/assets/images/logo/down_arrow_02.svg"
                    />
                    <p className="home_accordion_title margin_0">
                      {props.strings["home_text_22"]}
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="black_bg_text_white">
                    <div className="d-flex">
                      <FaSquare className="square_icon_accordion" />

                      <p className="pl-1 low_opacity">
                        {props.strings["home_text_23"]}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <div className="product_dir_button">
                <Link to="/products">
                  <button className="btn product_dir_btn">    {props.strings["49"]}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

BlackBgSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(BlackBgSection));
