import React, { useState, useEffect } from "react";
import "../assets/css/Sss.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaSquare } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import accordionData from "../data/accordionData.json";
import accordionDataEn from "../data/accordionDataEn.json";


import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// ..
AOS.init();
function Sss(props) {
  const { currentLanguageCode } = props;
  const [expanded, setExpanded] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [divHeight, setDivHeight] = useState(0);
  const [divOpacity, setDivOpacity] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowDiv(true);

      setDivOpacity(1);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Header />
      <main className="sss">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div className="row m-0 sss_border_bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex justify-content-center">
              {currentLanguageCode == "tr" ? (
                <img
                  className="res_sss_gif"
                  src="/assets/images/main/sss_gif.gif"
                />
              ) : (
                <img
                  className="res_sss_gif"
                  src="/assets/images/main/visox_faq.gif"
                />
              )}
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="3000"
        >
          {showDiv && (
            <div
              className="container"
              style={{
                position: "relative",
                minHeight: "100vh",
                zIndex: "9",
              }}
            >
              <div
                className="row d-flex justify-content-center"
                style={{
                  position: "relative",
                  bottom: 0,
                  left: 0,

                  top: "30%",
                  opacity: divOpacity,
                  transition: "height 1s, opacity 1s",
                }}
              >
           
                 
                 {currentLanguageCode == "tr" ? ( <div className="col-lg-10 center_accordion">
                    <div className="pt-3">
                      
                    {accordionData.map((item) => (
                        <Accordion
                          key={item.id}
                          className="mb-4"
                          expanded={expanded === item.id}
                          onChange={handleChange(item.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                          >
                            <Typography className="d-flex">
                              <img
                                className="accordion_down_icon_top"
                                src="/assets/images/logo/down_arrow_02.svg"
                                alt="Icon"
                              />
                              <p className="home_accordion_title margin_0">
                                {item.title}
                              </p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="d-flex">
                                <FaSquare className="square_icon_accordion" />
                                <p className="pl-1 low_opacity sss_mb">
                                  {item.items.map((listItem, index) => (
                                    <span className="sss_mb" key={index}>
                                      {index > 0 && <br />}• {listItem}
                                    </span>
                                  ))}
                                </p>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}

                   
                    </div>
                  </div>) : ( <div className="col-lg-10 center_accordion">
                    <div className="pt-3">
                      
                    {accordionDataEn.map((item) => (
                        <Accordion
                          key={item.id}
                          className="mb-4"
                          expanded={expanded === item.id}
                          onChange={handleChange(item.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                          >
                            <Typography className="d-flex">
                              <img
                                className="accordion_down_icon_top"
                                src="/assets/images/logo/down_arrow_02.svg"
                                alt="Icon"
                              />
                              <p className="home_accordion_title margin_0">
                                {item.title}
                              </p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="d-flex">
                                <FaSquare className="square_icon_accordion" />
                                <p className="pl-1 low_opacity sss_mb">
                                  {item.items.map((listItem, index) => (
                                    <span className="sss_mb" key={index}>
                                      {index > 0 && <br />}• {listItem}
                                    </span>
                                  ))}
                                </p>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}

                   
                    </div>
                  </div>) }
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}

Sss.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Sss));
