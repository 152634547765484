import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../assets/css/Need.css";
import { Slide } from "react-reveal";
import Roll from "react-reveal/Roll";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Need = (props) => {
  const { currentLanguageCode } = props;
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q44v1oq",
        "template_f8bn9sy",
        form.current,
        "ECLYa8BAKpnVhRZK6"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }

  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 1000); // 1 saniye gecikme süresi
    return () => clearTimeout(timer);
  }, []);

  const [kurumTuru, setKurumTuru] = useState(""); // Seçilen kurum türünü tutan state

  const handleKurumTuruChange = (e) => {
    setKurumTuru(e.target.value); // Seçilen kurum türünü güncelle
  };

  return (
    <>
      <Header />
      <ToastContainer />

      <main className="need_page">
        <div className="">
          <div className="row m-0  center_row_need">
            <div className="col-lg-4 need_page_text">
              <div className="d-flex flex-column justify-content-center ">
                <Slide bottom>
                  <h1 className="stroke_title_need">{props.strings["bana"]}</h1>
                  <h1 className="need_title">{props.strings["ne"]}</h1>
                  <h1 className="stroke_title_need">
                    {props.strings["lazım"]}
                  </h1>
                </Slide>
              </div>
              <img
                className="need_res"
                src="/assets/images/logo/visox_icon_outline_gray.svg"
                alt="Rotating Image"
              />
            </div>{" "}
            <div className="col-lg-4 col-md-8 res_form_padding">
              <form onSubmit={sendEmail} ref={form}>
                <select
                  name="organization"
                  className="form-select"
                  aria-label="Default select example"
                  value={kurumTuru}
                  onChange={handleKurumTuruChange}
                >
                  <option selected disabled>
                    {props.strings["40"]}
                  </option>
                  <option value="kurumsal">{props.strings["41"]}</option>
                  <option value="bireysel">{props.strings["42"]}</option>
                </select>
                {kurumTuru === "kurumsal" && (
                  <div className="form-floating mb-3">
                    <input
                      name="firm"
                      type="text"
                      className="form-control"
                      id="floatingInput boyut"
                      placeholder="firma adı"
                    />
                    <label htmlFor="floatingInput">{props.strings["43"]}</label>
                  </div>
                )}
                <div className="form-floating mb-3">
                  <input
                    name="place"
                    type="text"
                    className="form-control"
                    id="floatingInput boyut"
                    placeholder="firma adı"
                  />
                  <label htmlFor="floatingInput">{props.strings["44"]}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="people"
                    type="number"
                    className="form-control"
                    id="floatingInput kişi_sayısı"
                    placeholder="kullanacak kişi sayısı"
                  />
                  <label htmlFor="floatingInput">
                    {props.strings["need_text_3"]}{" "}
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="name_surname"
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="lorem ıpsum"
                  />
                  <label htmlFor="floatingInput">{props.strings["45"]}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    id="floatingInput email"
                    placeholder="e-posta"
                  />
                  <label htmlFor="floatingInput">
                    {props.strings["need_text_5"]}
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="phone"
                    type="number"
                    className="form-control"
                    id="floatingInput telefon"
                    placeholder="telefon"
                  />
                  <label htmlFor="floatingInput">
                    {props.strings["need_text_4"]}
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    name="message"
                    className="form-control"
                    id="exampleFormControlTextarea1 message"
                    rows="3"
                    placeholder="mesaj"
                  ></textarea>
                  <label htmlFor="floatingInput"> {props.strings["product_text_8"]}</label>
                </div>
                <div className="row pt-3">
                  <div className="col-lg-12 text-center up">
                    <button
                      type="submit"
                      className="btn need_contact_button padding_button_need"
                    >
                      {props.strings["need_text_6"]}
                    </button>
                  </div>
                </div>{" "}
              </form>
            </div>
            <div className="col-lg-4 d-flex justify-content-center ">
              <div className="">
                <Roll bottom>
                  <img
                    className="need_gif"
                    src="/assets/images/logo/visox_icon_outline_gray.svg"
                    alt="Rotating Image"
                  />
                </Roll>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

Need.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Need));
