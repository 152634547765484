import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <div className="wrapper white-bg">
        <section className="vh-height page_404 white-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="centerize-col text-center">
                  <div className="four-zero-four-bg">
                    <h1 className="dark-color">404 ERROR</h1>
                  </div>
                  <div className="content-box">
                    <p className="dark-color lead">The page you are looking for not available!</p>
                    <p className="mt-30"><Link to="/" className="btn btn-color btn-square">GO TO HOME</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>




    </>
  )
}

export default NotFound