import React, { useState, useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "../../assets/css/ProductsView.css";
import ReactHtmlParser from "react-html-parser";

var arview = [
  {
    width1: "20vw",
    width2: "25vw",
    height1: "30vh",
    height2: "30vh",
    marginL1: "1vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "1vw",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "oneBoxImage",
    boxTitleOne: "estetik tasarım",
    boxDescriptionOne: '8" ekran boyutu',
    boxTwoCss: "twoBoxImage",
    boxTitleTwo: "dokunmatik ekran",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/1.png",
    imageTwo: "/assets/images/plus/2.png",
  },
  {
    width1: "25vw",
    width2: "20vw",
    height1: "30vh",
    height2: "30vh",
    marginL1: "-5vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "0",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "threeBoxImagePlus",
    boxTitleOne: "yüz <br/> tanıma <br/> kart <br/> okuyucu",
    boxDescriptionOne: "desteği",
    boxTwoCss: "fourBoxImage",
    boxTitleTwo: "hızlı yüz tanıma",
    boxDescriptionTwo: "< 0,15 saniye",
    imageOne: "/assets/images/plus/3.png",
    imageTwo: "/assets/images/plus/4.png",
  },
  {
    width1: "20vw",
    width2: "0",
    height1: "61vh",
    height2: "0",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "0",
    marginL2: "0",
    marginR2: "0",
    marginB2: "0",
    boxOneCss: "fiveBoxImage",
    boxTitleOne: "yüz <br/> tanıma <br/> mesafesi",
    boxDescriptionOne: "0,3-2 metre",
    boxTwoCss: "",
    boxTitleTwo: "",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/5.png",
    imageTwo: "",
  },
  {
    width1: "20vw",
    width2: "0",
    height1: "61vh",
    height2: "0",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "0",
    marginL2: "",
    marginR2: "0",
    marginB2: "0",
    boxOneCss: "sixBoxImage",
    boxTitleOne: "kullanıcı kapasitesi <br/> 50.000 yüz ve kart",
    boxDescriptionOne: "log kapasitesi <br/> 100.000 hareket kaydı",
    boxTwoCss: "",
    boxTitleTwo: "",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/6.png",
    imageTwo: "",
  },
  {
    width1: "25vw",
    width2: "30vw",
    height1: "30vh",
    height2: "30vh",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "threeBoxImage",
    boxTitleOne: "yüz <br/> tanıma <br/> doğruluğu",
    boxDescriptionOne: "> %99.8",
    boxTwoCss: "threeBoxImage",
    boxTitleTwo: "çalışma <br/> sıcaklığı",
    boxDescriptionTwo: "-10C ile 50C arası",
    imageOne: "/assets/images/plus/7.png",
    imageTwo: "/assets/images/plus/8.png",
  },
  {
    width1: "25vw",
    width2: "20vw",
    height1: "30vh",
    height2: "30vh",
    marginL1: "-5vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "0",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "oneBoxImagePlus",
    boxTitleOne: "hızlı kart okuma",
    boxDescriptionOne: "< 1 saniye",
    boxTwoCss: "sixBoxImage",
    boxTitleTwo: "WiFi",
    boxDescriptionTwo: "ağına bağlı",
    imageOne: "/assets/images/plus/9.png",
    imageTwo: "/assets/images/plus/10.png",
  
  },
];
var arviewMobile = [
  {
    width1: "40vw",
    width2: "45vw",
    height1: "25vh",
    height2: "25vh",
    marginL1: "1vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "1vw",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "oneBoxImage",
    boxTitleOne: "minimal tasarım",
    boxDescriptionOne: '3" ekran boyutu',
    boxTwoCss: "twoBoxImage",
    boxTitleTwo: "dokunmatik ekran",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/1.png",
    imageTwo: "/assets/images/plus/2.png",
  },
  {
    width1: "45vw",
    width2: "40vw",
    height1: "25vh",
    height2: "25vh",
    marginL1: "-5vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "0",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "threeBoxImage",
    boxTitleOne: "yüz <br/> tanıma <br/> kart <br/> okuyucu",
    boxDescriptionOne: "desteği",
    boxTwoCss: "fourBoxImage",
    boxTitleTwo: "hızlı yüz tanıma",
    boxDescriptionTwo: "<0,15 saniye",
    imageOne: "/assets/images/plus/3.png",
    imageTwo: "/assets/images/plus/4.png",
  },
  {
    width1: "35vw",
    width2: "0",
    height1: "51vh",
    height2: "0",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "0",
    marginL2: "0",
    marginR2: "0",
    marginB2: "0",
    boxOneCss: "fiveBoxImage",
    boxTitleOne: "yüz <br/> tanıma <br/> mesafesi",
    boxDescriptionOne: "0,3-2 metre",
    boxTwoCss: "",
    boxTitleTwo: "",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/5.png",
    imageTwo: "",
  },
  {
    width1: "35vw",
    width2: "0",
    height1: "51vh",
    height2: "0",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "0",
    marginL2: "",
    marginR2: "0",
    marginB2: "0",
    boxOneCss: "sixBoxImage",
    boxTitleOne: "kullanıcı kapasitesi <br/> 50.000 yüz ve kart",
    boxDescriptionOne: "log kapasitesi <br/> 100.000 hareket kaydı",
    boxTwoCss: "",
    boxTitleTwo: "",
    boxDescriptionTwo: "",
    imageOne: "/assets/images/plus/6.png",
    imageTwo: "",
  },
  {
    width1: "55vw",
    width2: "60vw",
    height1: "25vh",
    height2: "25vh",
    marginL1: "0",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "threeBoxImage",
    boxTitleOne: "yüz <br/> tanıma <br/> doğruluğu",
    boxDescriptionOne: "> %99.8",
    boxTwoCss: "threeBoxImage",
    boxTitleTwo: "çalışma <br/> sıcaklığı",
    boxDescriptionTwo: "-10C ile 50C arası",
    imageOne: "/assets/images/plus/7.png",
    imageTwo: "/assets/images/plus/8.png",
  },
  {
    width1: "60vw",
    width2: "55vw",
    height1: "25vh",
    height2: "25vh",
    marginL1: "-5vw",
    marginR1: "1vw",
    marginB1: "1vh",
    marginL2: "0",
    marginR2: "1vw",
    marginB2: "1vh",
    boxOneCss: "oneBoxImage",
    boxTitleOne: "hızlı kart okuma",
    boxDescriptionOne: "< 1 saniye",
    boxTwoCss: "sixBoxImage",
    boxTitleTwo: "WiFi",
    boxDescriptionTwo: "ağına bağlı",
    imageOne: "/assets/images/plus/9.png",
    imageTwo: "/assets/images/plus/10.png",
  },
];

const getItems = () =>
  Array(6)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function ProductViewPlus() {
  const [isMobile, setIsMobile] = useState(window.innerWidth);

  const [items, setItems] = React.useState(getItems);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <ScrollMenu>
      {items.map(({ id }, index) => (
        <Card
          itemId={id} // NOTE: itemId is required for track items
          title={id}
          key={id}
          boxTitleOne={
            isMobile > 1000
              ? arview[index].boxTitleOne
              : arviewMobile[index].boxTitleOne
          }
          boxDescriptionOne={
            isMobile > 1000
              ? arview[index].boxDescriptionOne
              : arviewMobile[index].boxDescriptionOne
          }
          boxOneCss={
            isMobile > 1000
              ? arview[index].boxOneCss
              : arviewMobile[index].boxOneCss
          }
          boxTitleTwo={
            isMobile > 1000
              ? arview[index].boxTitleTwo
              : arviewMobile[index].boxTitleTwo
          }
          boxDescriptionTwo={
            isMobile > 1000
              ? arview[index].boxDescriptionTwo
              : arviewMobile[index].boxDescriptionTwo
          }
          boxTwoCss={
            isMobile > 1000
              ? arview[index].boxTwoCss
              : arviewMobile[index].boxTwoCss
          }
          width1={
            isMobile > 1000 ? arview[index].width1 : arviewMobile[index].width1
          }
          width2={
            isMobile > 1000 ? arview[index].width2 : arviewMobile[index].width2
          }
          height1={
            isMobile > 1000
              ? arview[index].height1
              : arviewMobile[index].height1
          }
          height2={
            isMobile > 1000
              ? arview[index].height2
              : arviewMobile[index].height2
          }
          marginL1={
            isMobile > 1000
              ? arview[index].marginL1
              : arviewMobile[index].marginL1
          }
          marginR1={
            isMobile > 1000
              ? arview[index].marginR1
              : arviewMobile[index].marginR1
          }
          marginB1={
            isMobile > 1000
              ? arview[index].marginB1
              : arviewMobile[index].marginB1
          }
          marginL2={
            isMobile > 1000
              ? arview[index].marginL2
              : arviewMobile[index].marginL2
          }
          marginR2={
            isMobile > 1000
              ? arview[index].marginR2
              : arviewMobile[index].marginR2
          }
          marginB2={
            isMobile > 1000
              ? arview[index].marginB2
              : arviewMobile[index].marginB2
          }
          imageOne={
            isMobile > 1000
              ? arview[index].imageOne
              : arviewMobile[index].imageOne
          }
          imageTwo={
            isMobile > 1000
              ? arview[index].imageTwo
              : arviewMobile[index].imageTwo
          }
       
          onClick={handleClick(id)}
          selected={isItemSelected(id)}
        />
      ))}
    </ScrollMenu>
  );
}

function Card({
  imageTwo,
  imageOne,
  boxTitleOne,
  boxDescriptionOne,
  boxTwoCss,
  boxTitleTwo,
  boxDescriptionTwo,
  boxOneCss,
  title,
  itemId,
  width1,
  width2,
  marginB1,
  marginB2,
  marginL1,
  marginL2,
  marginR1,
  marginR2,
  height1,
  height2,
}) {
  const visibility = React.useContext(VisibilityContext);


 

  return (
    <>
      <div
        className={boxOneCss + " boxcss"}
        style={{
          width: width1,
          height: height1,
          marginLeft: marginL1,
          marginRight: marginR1,
          marginBottom: marginB1,
        }}
      >
       <span>
          {" "}
          {ReactHtmlParser(boxTitleOne)}
          <br />
          <h6>{ReactHtmlParser(boxDescriptionOne)}</h6>
          
        </span>
        <img src={imageOne} className="img-fluid" />
     
        
      </div>
      {width2 == "0" ? (
        <></>
      ) : (
        <div
          className={boxTwoCss + " boxcss"}
          style={{
            width: width2,
            height: height2,
            marginLeft: marginL2,
            marginRight: marginR2,
            marginBottom: marginB2,
          }}
        >
          <img src={imageTwo} className="img-fluid" />
         
      
          <span>
          {" "}
          {ReactHtmlParser(boxTitleTwo)}
          <br />
          <h6>{ReactHtmlParser(boxDescriptionTwo)}</h6>
          
        </span>
        </div>
      )}
    </>
  );
}

export default ProductViewPlus;
